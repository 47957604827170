.princess {
    font-family: 'Princess Sofia', cursive;
    font-size: 1.25em;
}

.heading2Xl {
    font-size: 3rem;
    line-height: 1.2;
    font-weight: 800;
    letter-spacing: -0.05rem;
    margin: 1rem 0;
}

@media screen and (min-width: 800px) {
    .heading2Xl {
        font-size: 4rem;
    }
}

.headingXl {
    font-size: 2rem;
    line-height: 1.3;
    font-weight: 800;
    letter-spacing: -0.05rem;
    margin: 1rem 0;
}

.headingLg {
    font-size: 1.5rem;
    line-height: 1.4;
    margin: 1rem 0;
}

.headingMd {
    font-size: 1.2rem;
    line-height: 1.5;
}

.center {
    text-align: center;
}

.borderCircle {
    border-radius: 9999px;
}

.colorInherit {
    color: inherit;
}

.padding1px {
    padding-top: 1px;
}

.card {
    margin: 1rem;
    flex-basis: 45%;
    padding: 1.5rem;
    text-align: left;
    color: inherit;
    text-decoration: none;
    border: 1px solid #eaeaea;
    border-radius: 10px;
    transition: color 0.15s ease, border-color 0.15s ease;
}

.card h3 {
    margin: 0 0 1rem 0;
    font-size: 1.5rem;
}

.card p {
    margin: 0;
    font-size: 1.25rem;
    line-height: 1.5;
}
