@import url(https://fonts.googleapis.com/css2?family=Princess+Sofia&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap);
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html, body {
    font-family: 'Roboto', sans-serif;
}

body {
    font-weight: 300;
    font-size: .9rem;
}

img {
    max-width: 100%;
}

h1, h2, h3, h4, h5 {
    font-family: 'Lato', sans-serif;
    font-weight: 300;
    font-style: normal;
    margin-top: 1.5rem;
    margin-bottom: .75rem;
}

h1 a {
    color: black;
}

h1 a:hover {
    color: #1f1f1f;
}


h2 {
    font-size: 2em;
}

h3 {
    font-size: 1.5em;
}

h4 {
    font-size: 1.3em;
}

h5 {
    font-size: 1.2em;
}

h6 {
    font-size: 0.8em;
}

p {
    font-size: .9rem;
    margin: .5rem 0;
}

a {
    color: #6CAC93;
    text-decoration: none;
}

a:hover {
    color: #6CAC93;
}

ul {
    list-style-type: square;
    margin-top: 1rem;
}

ul li {
    margin-bottom: .35rem;
}

.container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.header {
    padding-top: 1rem;
}

.main {
    max-width: 1000px;
    width: 100%;
    padding: 2rem;
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.column-layout {
    display: flex;
    flex-direction: column;
}

.playerWrapper {
    flex: 1 1 50%;
    margin: 0 0 2rem 0;
    align-self: stretch;
}

.play-info {
    font-size: .75rem;
    line-height: 1.5;
    max-width: 400px;
    margin: 1rem auto;
    text-align: center;
    font-style: italic;
}

.store-links {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.store-links > a {
    flex: 1 1 50%;
    display: flex;
    align-items: center;
}

.store-links > a.google-play {
    justify-content: flex-end;
}

.store-links > a.app-store {
    justify-content: flex-start;
}

.store-links img {
    width: 100%;
    max-width: 175px;
}

.footer {
    border-top: 1px solid #efefef;
    width: 100%;
    text-align: center;
    padding: .5rem 0;
}

.footer p {
    font-size: .8rem;
    color: #1a2e26;
}

.footer hr {
    display: inline-block;
    height: 1px;
    width: 25px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: .25rem 0;
    padding: 0;
}

.info-container {
    max-width: 400px;
}

.terms {
    background-color: #efefef;
    padding: 1rem;
}

.terms p {
    line-height: 1.5;
    text-align: justify;
}

.terms ul {
    list-style-position: inside;
}

.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
}

.video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}


.app-visualiser {

}

.nps-rating {
    text-align: center;
    margin-top: 1rem;
}

.nps-rating p {
    margin-top: 0;
}

.nps-ratings-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    max-width: 240px;
}

.nps-ratings-wrapper .rating {
    margin-left: .5rem;
    font-weight: bold;
}

.nps-ratings-wrapper .img-wrapper {
    flex: 1 1 20%;
    padding: .15rem;
}

@media screen and (min-width: 800px) {
    .main {
        flex-direction: row;
        align-items: flex-start;
    }

    .playerWrapper {
        align-self: center;
        margin: 0 2rem 0 0;
        max-width: 500px;
    }

    .info-container {
        padding-left: 2rem;
        max-width: none;
        flex: 1 1;
    }

    .info-header {
        margin-top: 0;
    }
}

.utils_princess__2XEyx {
    font-family: 'Princess Sofia', cursive;
    font-size: 1.25em;
}

.utils_heading2Xl__2MwOh {
    font-size: 3rem;
    line-height: 1.2;
    font-weight: 800;
    letter-spacing: -0.05rem;
    margin: 1rem 0;
}

@media screen and (min-width: 800px) {
    .utils_heading2Xl__2MwOh {
        font-size: 4rem;
    }
}

.utils_headingXl__3PLyJ {
    font-size: 2rem;
    line-height: 1.3;
    font-weight: 800;
    letter-spacing: -0.05rem;
    margin: 1rem 0;
}

.utils_headingLg__2fvot {
    font-size: 1.5rem;
    line-height: 1.4;
    margin: 1rem 0;
}

.utils_headingMd__1JF2P {
    font-size: 1.2rem;
    line-height: 1.5;
}

.utils_center__3dLyI {
    text-align: center;
}

.utils_borderCircle__1HKnO {
    border-radius: 9999px;
}

.utils_colorInherit__1ChUn {
    color: inherit;
}

.utils_padding1px__3Yy04 {
    padding-top: 1px;
}

.utils_card__25qcm {
    margin: 1rem;
    flex-basis: 45%;
    padding: 1.5rem;
    text-align: left;
    color: inherit;
    text-decoration: none;
    border: 1px solid #eaeaea;
    border-radius: 10px;
    transition: color 0.15s ease, border-color 0.15s ease;
}

.utils_card__25qcm h3 {
    margin: 0 0 1rem 0;
    font-size: 1.5rem;
}

.utils_card__25qcm p {
    margin: 0;
    font-size: 1.25rem;
    line-height: 1.5;
}

